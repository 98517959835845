import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import classNames from "classnames"
// import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = ({ siteTitle }) => {
  const [isNavActive, setIsNavActive] = useState(false);

  const toggleNav = () => (setIsNavActive(!isNavActive))

  return (
  <nav className="navbar is-info is-fixed-top" role="navigation" aria-label="main navigation">
    <div className="container is-fluid">
      <div className="navbar-brand is-family-secondary">
        <h1 className="navbar-item">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <a
          role="button"
          className={classNames("navbar-burger", { "is-active": isNavActive })}
          aria-label="menu"
          aria-expanded="false"
          data-target="hesn-menu"
          onClick={toggleNav}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="hesn-menu" className={classNames("navbar-menu", { "is-active": isNavActive })}>
        <div className="navbar-start">
          <Link to="/" className="navbar-item" activeClassName="is-active">
            About
          </Link>
          <Link to="/help" className="navbar-item" activeClassName="is-active">
            Being Evicted? Get help here.
          </Link>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link to="/training" className="button is-warning is-rounded is-outlined is-small" activeClassName="is-focused">
                Train to be an Observer
              </Link>
              <Link to="/observe" className="button is-success is-rounded is-outlined is-small" activeClassName="is-focused">
                Observe a Case
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
