import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <section className="section has-background-light">
      <div className="container">
        <h3 className="title is-family-secondary">Join the Houston ESN by attending a training!</h3>
        <div className="content">
          <p>Anyone can train to be an Houston Eviction Solidarity Network Case Observer.
            Sign up for the <a href="https://www.keeptexanshoused.org/" target="_blank" rel="noopener noreferrer">Keep Texans Housed</a> movement below.
            Texas Housers staff will then invite you to a training.
          </p>
          <div className="lds-dual-ring">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfUrZh8JYlc4dGgLirxr5RxY8uNTIkj8xKu_bueKOe_hbkiZQ/viewform?embedded=true"
              width="100%"
              height="2800"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Sign Up to Join ESN"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
